@use '../abstracts/mixins' as *;

.socials__grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(78px, 1fr));
  align-content: center;
  justify-items: center;
}

.social__button {
  display: flex;
  width: 78px;
  height: 78px;
  border-radius: 0.5rem;
  background-color: var(--clr-blue-lightest);
  border: 1px solid var(--clr-blue);
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--clr-blue-light-hover);
  }
}

.social__icon {
  width: 48px;
  height: 48px;
  object-fit: contain;
  object-position: center;
}
