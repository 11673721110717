@use '../abstracts/mixins' as *;

.search-page {
  padding-top: 3rem;

  @include mq(medium) {
    padding-top: 4rem;
  }
}

.search-page__headline {
  margin-bottom: 2.5rem;

  @include mq(medium) {
    margin-bottom: 3.5rem;
  }
}

.search-page__search-label {
  display: flex;
  border: solid 1px #bdbdbd;
  border-radius: 0.5rem;
  position: relative;
  height: 3rem;
  width: 100%;
  box-sizing: border-box;
}

.search-page__search-label:focus-within {
  border: 2px solid var(--clr-blue);
}

.search-page__search-input {
  border: 0;
  outline: 0;
  width: 100%;
  font-size: 1rem;
  background-color: transparent;
  padding-left: 3rem;
  color: var(--clr-gray-dark);
}

.search-page__search-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
  object-position: center;
  position: absolute;
  left: 1rem;
  top: calc(50% - 12px);

  & g {
    stroke: var(--clr-gray-dark);
  }
}
