@use '../abstracts/mixins' as *;

.kpm-page__links {
  display: flex;
  gap: 1rem;
}

.kpm-page__links-link {
  max-width: 400px;
  width: 100%;
  padding: 1rem 1.5rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: var(--clr-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.kpm-page__links-progess {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  color: white;
  width: 100%;
}

.kpm-page__links-link-headline {
  font-weight: 700;
  font-size: var(--fs-500);
}

.kpm-page__privacy-start {
  margin-top: 1rem;
}

.kpm-dashboard__hightlight {
  font-weight: 700;
  font-size: var(--fs-500);
  color: var(--clr-red);
}
