.contact__search-bar {
  margin: 1.5rem 0 2.5rem 0;
}

.contact__search-label {
  display: flex;
  border: solid 1px #bdbdbd;
  border-radius: 0.5rem;
  position: relative;
  height: 3rem;
  width: 100%;
  box-sizing: border-box;
}

.contact__search-label:focus-within {
  border: 2px solid var(--clr-blue);
}

.contact__search-input {
  border: 0;
  outline: 0;
  width: 100%;
  font-size: 1rem;
  background-color: transparent;
  padding-left: 3rem;
  color: var(--clr-gray-dark);
}

.contact__search-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
  object-position: center;
  position: absolute;
  left: 1rem;
  top: calc(50% - 12px);

  & g {
    stroke: var(--clr-gray-dark);
  }
}

.contact__no-results {
  font-weight: 700;
}
