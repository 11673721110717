@use './label';

.fieldset {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  margin: 1.5rem 0 1rem 0;

  &__legend {
    font-size: var(--fs-500);
    margin-bottom: 1rem;
    color: var(--clr-blue);
    font-weight: bold;
  }
}

.fieldset.kpm {
  margin-top: 0;
}
