@use '../abstracts/mixins' as *;

.kpm-questionaire__next {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}

.kpm-questionaire__next-button {
  justify-self: flex-end;
}

.kpm-questionaire__pagination {
  margin-top: 3.5rem;
}
