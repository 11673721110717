@use '../../abstracts/mixins' as *;

.cta {
  padding: 1.5rem;
  background-color: var(--clr-blue-light);
  display: inline-block;
  flex-direction: column;
  width: 100%;
}

.cta__title {
  margin-bottom: 1.5rem;
}

.cta__text {
  color: var(--clr-blue);
  margin-bottom: 2rem;
}

.cta__button-container {
  display: flex;
  justify-content: center;
}
