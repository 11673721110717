@use '../abstracts/mixins' as *;

.outlet__cart-overview-mobile {
  padding-bottom: 0;
}

.outlet__cart-overview-body {
  border-top: 2px solid var(--clr-gray);
  border-bottom: 2px solid var(--clr-gray);
}

.outlet__overview-product-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.outlet__cart-overview-product {
  padding: 1rem;
  border-bottom: 1px solid var(--clr-gray);
  background-color: var(--clr-gray-lightest);

  &:nth-child(2n) {
    background-color: white;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.outlet__cart-overview-product a {
  text-decoration: none;
}

.outlet__cart-overview-product-body {
  display: flex;
  align-items: center;
  gap: 1.5rem 1rem;
  justify-content: space-between;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

.outlet__cart-overview-product-amount {
  display: flex;

  & input,
  .input {
    width: 70px;
    margin: 0;
    border-radius: 0;
  }
}

.outlet__cart-overview-total {
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
  margin-top: 2rem;
  font-weight: 700;
}

.outlet_cart-overview-total-label {
  margin-right: 0.5rem;
}

.outlet__cart-overview-decrease-amount {
  border-top: 1px solid #bdbdbd;
  border-left: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-right: 0;
  background-color: white;
  padding: 0 1rem;
  display: inline-flex;
  align-items: center;
  height: 48px;
  cursor: pointer;

  &:disabled,
  [disabled] {
    background-color: var(--clr-gray-lightest);
    cursor: not-allowed;
  }
}

.outlet__cart-overview-increase-amount {
  border-top: 1px solid #bdbdbd;
  border-right: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-left: 0;
  background-color: white;
  padding: 0 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  cursor: pointer;
}

.outlet-cart-overview__item-image {
  height: 60px;
  width: 60px;
  object-fit: contain;
  object-position: center;
}
