@use '../abstracts/mixins' as *;

.notification__container {
  padding: 2rem 0 1rem 0;
}

.notification {
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }

  &--error,
  &--rot {
    background-color: rgba(248, 215, 218, 0.3);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-red-light);

    & .notification__headline {
      color: var(--clr-red-dark);
    }
  }

  &--warning,
  &--gelb {
    background-color: rgba(255, 243, 205, 0.35);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-yellow-light);

    & .notification__headline {
      color: var(--clr-yellow-dark);
    }
  }

  &--notification,
  &--blau {
    background-color: rgba(232, 244, 252, 0.4);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-blue-light);

    & .notification__headline {
      color: var(--clr-blue);
    }
  }

  &--weiss {
    border: 2px solid rgba(232, 244, 252, 0.4);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-gray-light);

    & .notification__headline {
      color: var(--clr-blue);
    }
  }

  &__headline {
    font-size: var(--fs-400);
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  a {
    display: inline-block;
    text-decoration: none;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      transition: width 0.3s;
      position: absolute;
      bottom: 1px;
      background: var(--clr-blue);
    }

    &:hover::after {
      width: 100%;
      transition: width 0.3s;
    }

    &:hover,
    &:focus {
      color: var(--clr-blue);
    }
  }

  a[href*="//"]:not([href*="lisa.werkbank.de"]):not([href*="lisa.molkerei-ammerland.de"])
  {
    position: relative;
    margin-right: 1.1rem;
  }

  a[href^='mailto:'] {
    position: relative;
    margin-right: 1.8rem;
  }

  a[href*="//"]:not([href*="lisa.werkbank.de"]):not([href*="lisa.molkerei-ammerland.de"])::before
  {
    position: absolute;
    right: -1.5rem;
    top: calc(50% - 11px);
    content: ' ';
    width: 24px;
    height: 24px;
    background: url('/image-icons/arrow-right-up-blue.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  a[href^='mailto:']::before {
    position: absolute;
    right: -1.5rem;
    top: calc(50% - 10px);
    content: ' ';
    width: 18px;
    height: 18px;
    background: url('/image-icons/mail.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
