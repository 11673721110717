.back-link {
  display: flex;
  align-items: center;
  color: var(--clr-blue);
  text-decoration: none;
  font-weight: 700;
  font-size: var(--fs-500);
  margin-bottom: 2rem;
}

.back-link__arrow {
  margin-right: 0.5rem;
}
