.nachhaltigkeitsmodul {
  .container-agb-modul {
    margin-bottom: 8px;
  }
  .container-agb-thuenen {
    margin-bottom: 16px;
  }
  .checkbox {
    align-items: flex-start;
    button {
      margin-top: 5px;
    }
  }
  .button--primary {
    &:disabled {
      background-color: var(--clr-gray-light) !important;
      color: var(--clr-gray-dark) !important;
      cursor: not-allowed !important;

      &:hover {
        background-color: var(--clr-gray-light) !important;
        color: var(--clr-gray-dark) !important;
      }
    }
  }
}
