@use '../../abstracts/mixins' as *;

.SwitchRoot {
  all: unset;
  width: 34px;
  height: 20px;
  background-color: white;
  border: 1px solid #bdbdbd;
  border-radius: 9999px;
  position: relative;
  -webkit-tap-highlight-color: var(--clr-blue);
}
.SwitchRoot:focus {
  box-shadow: 0 0 0 2px (--clr-gray-dark);
  outline: 1px solid var(--clr-blue);
}

.SwitchRoot[data-state='checked'] {
  background-color: var(--clr-blue);
  border-color: var(--clr-blue);
}

.SwitchThumb {
  display: block;
  width: 14px;
  height: 14px;
  background-color: #bdbdbd;
  border-radius: 9999px;
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
}
.SwitchThumb[data-state='checked'] {
  transform: translateX(19px);
  background-color: white;
}

.Label {
  color: white;
  font-size: 15px;
  line-height: 1;
}
