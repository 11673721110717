@use '../abstracts/mixins' as *;

.user-notification--success {
  width: 100%;
  text-align: center;
  background-color: var(--clr-blue-lightest);
  color: var(--clr-blue);
  padding: 0.25rem 1rem;

  & a {
    color: var(--clr-blue);
    margin-left: 0.25rem;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      color: var(--clr-blue);
    }
  }
}

.user-notification--error {
  width: 100%;
  text-align: center;
  background-color: var(--clr-pink);
  color: var(--clr-red-dark);
  padding: 0.25rem 1rem;

  & a {
    color: var(--clr-red-dark);
    margin-left: 0.25rem;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      color: var(--clr-red-dark);
    }
  }
}

.user-notification--warning {
  width: 100%;
  text-align: center;
  background-color: var(--clr-yellow-light);
  color: var(--clr-yellow-dark);
  padding: 0.25rem 1rem;

  & a {
    color: var(--clr-yellow-dark);
    margin-left: 0.25rem;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      color: var(--clr-yellow-dark);
    }
  }
}
