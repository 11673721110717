@use '../abstracts/mixins' as *;

.profile__sub-headline {
  margin-bottom: 1.5rem;
}

.profile__category {
  padding: 0.75rem 0;
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  flex-wrap: wrap;
  gap: 0.25rem 1rem;
  flex-direction: column;

  @include mq(small) {
    flex-direction: row;
    padding: 0.5rem 0;
  }
}

.profile__category-headline {
  font-weight: 700;
}

.profile__password-data {
  color: var(--clr-blue);
  display: inline-block;
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: var(--clr-blue);
    transition: width 0.3s;
    position: absolute;
    bottom: 1px;
  }

  &:hover::after {
    width: 100%;
    transition: width 0.3s;
  }

  &:hover,
  &:focus {
    color: var(--clr-blue);
  }
}

.profile__email-consent-revert {
  background-color: transparent;
  color: var(--clr-gray-dark);
  text-decoration: underline;
  border: 0;
  padding: 0;
  cursor: pointer;
  margin-top: 0.25rem;
}

.profile__farm-certificate {
  margin-top: 3rem;
}

.profile__button-link {
  background-color: transparent;
  text-decoration: underline;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: var(--clr-blue);
  display: inline-block;
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: var(--clr-blue);
    transition: width 0.3s;
    position: absolute;
    bottom: 1px;
  }

  &:hover::after {
    width: 100%;
    transition: width 0.3s;
  }
}
