@media print {
  .header {
    display: none;
  }

  .navigation {
    display: none;
  }

  .footer {
    display: none !important;
  }
}
