.newspage__intro {
  margin-top: 2rem;
  font-weight: 700;
}

.newspage__link a {
  margin-top: 3rem;
  color: var(--clr-blue);
  display: inline-block;
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: var(--clr-blue);
    transition: width 0.3s;
    position: absolute;
    bottom: 1px;
  }

  &:hover::after {
    width: 100%;
    transition: width 0.3s;
  }

  &:hover,
  &:focus {
    color: var(--clr-blue);
  }
}

.newspage__categories {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.newspage__category {
  color: var(--clr-blue);
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
}

.newspage__category-icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}

.news__buttons-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 0.75rem 1.5rem;
}

.news-listing__no-results {
  margin: 2rem 0;
  font-weight: 700;
}
