@use '../../abstracts/mixins' as *;

.banner {
  margin-top: 2rem;
  padding-top: 0;
}

.banner__image-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  overflow: hidden;
  height: auto;

  &.dashboard {
    padding: 0;
  }

  @include mq(large) {
    // Banner slightly larger than container on large screens
    width: min(100%, 73rem);
  }
}

.banner__image {
  width: 100%;
  height: auto;
  max-height: 455px;
  object-fit: cover;
}

.banner__image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, #ffffff 100%);
}
