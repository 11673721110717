@use '../abstracts/mixins' as *;

.mymilk-dashboard__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    'headline1'
    'pickup'
    'headline2'
    'labdata';

  @include mq(medium) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'headline1 headline2'
      'pickup labdata';
  }
}

.mymilk-dashboard__lab-data-box {
  padding: 1.5rem;
  background-color: var(--clr-gray-lightest);
  border: 1px solid var(--clr-gray-light);
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  text-decoration: none;
  color: var(--clr-gray-dark);
  transition: all 0.15s ease-in-out;
  grid-area: labdata;

  &:hover {
    color: var(--clr-gray-dark);
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  }
}

.mymilk-dashboard__last-collection-box {
  padding: 1.5rem;
  background-color: var(--clr-gray-lightest);
  border: 1px solid var(--clr-gray-light);
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  text-decoration: none;
  color: var(--clr-gray-dark);
  transition: all 0.15s ease-in-out;
  grid-area: pickup;

  &:hover {
    color: var(--clr-gray-dark);
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  }
}

.mymilk-dashboard__information {
  font-weight: 700;
  font-size: var(--fs-500);
}

// Colors for Backround defined in my-milk-tables.scss

.my-milk__warning--dashboard {
  justify-content: flex-start;
}

.mymilk-dashboard__last-collection-headline {
  grid-area: headline1;
  font-size: var(--fs-600);
}

.mymilk-dashboard__lab-data-headline {
  grid-area: headline2;
  font-size: var(--fs-600);
}
