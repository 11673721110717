@use '../abstracts/mixins' as *;

.nav-quick__container {
  display: grid;
  grid-template-rows: auto;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  align-items: stretch;
  justify-items: center;
  width: 100%;

  @include mq(tiny) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include mq(medium) {
    display: flex;
    justify-content: space-between;
  }
}

.nav-quick__element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  cursor: pointer;
  width: 100%;
  @include transition(all);

  &:hover {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
  }

  @include mq(small) {
    padding: 1rem;
  }

  & .nav-quick__description {
    margin-top: 0.25rem;
    font-weight: 700;
    font-size: var(--fs-300);
  }
}

.nav-quick__link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-quick__icon {
  height: 3rem;
  width: 3rem;
  object-fit: contain;
  object-position: center;

  @include mq(small) {
    height: 3.75rem;
    width: 3.75rem;
  }
}

.nav-quick__description {
  hyphens: none;
  text-align: center;
}
