@use '../../abstracts/mixins' as *;

.media__element {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    .media__image-container {
      transform: scale(0.975);
      transition: 0.2s ease-in-out;
    }
  }
}

.media__element-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--clr-black);

  &:hover,
  &:focus,
  &:active {
    color: var(--clr-black);
  }
}

.media__image-container {
  width: 100%;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
}

.media__image {
  width: 100%;
  height: auto;
  max-width: 500px;
  object-fit: cover;
}

.media__title {
  margin-top: 0.375rem;
}
