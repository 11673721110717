@use '../abstracts/mixins' as *;
@use '../utilities/container';

.lexicon-page {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.lexicon__headline {
  margin-bottom: 3.5rem;
  text-transform: capitalize;
}

.lexicon__search-label {
  display: flex;
  border: solid 1px #bdbdbd;
  border-radius: 0.5rem;
  position: relative;
  height: 3rem;
  width: 100%;
  box-sizing: border-box;
}

.lexicon__search-label:focus-within {
  border: 2px solid var(--clr-blue);
}

.lexicon__search-input {
  border: 0;
  outline: 0;
  width: 100%;
  font-size: 1rem;
  background-color: transparent;
  padding-left: 3rem;
  color: var(--clr-gray-dark);
}

.lexicon__search-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
  object-position: center;
  position: absolute;
  left: 1rem;
  top: calc(50% - 12px);

  & g {
    stroke: var(--clr-gray-dark);
  }
}
