@use '../../abstracts/mixins' as *;

.media-grid-block__title {
  margin-bottom: 1.5rem;
}

.media-grid-block__description {
  margin-bottom: 1.5rem;
}

.media__title {
  margin-bottom: 1rem;
}

.media-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-column-gap: 1.5rem;
  grid-row-gap: 0.5rem;
  align-items: start;
}

.media-grid--images {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-column-gap: 1.5rem;
  grid-row-gap: 2.5rem;
  justify-items: center;
  align-items: start;

  @include mq(small) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq(medium) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include mq(large) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  & .media-grid__element-link {
    flex-direction: column;
  }

  & .media-grid__title {
    text-align: left;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.media-grid__element {
  display: flex;
  align-items: center;

  & a {
    &:hover ::after {
      display: none;
    }
  }
}

.media-grid__element-link {
  display: inline-flex;
  align-items: center;
  text-align: left;
  color: var(--clr-blue);
  text-decoration: none;
  position: relative;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  @include mq(small) {
    text-align: center;
  }

  & .media-grid__title::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: var(--clr-blue);
    transition: width 0.3s;
    position: absolute;
    bottom: 1px;
  }

  &:hover .media-grid__title::after {
    width: 100%;
    transition: width 0.3s;
  }
}

.media-grid--images .media-grid__element-link {
  text-align: center;

  @include mq(small) {
    text-align: center;
  }
}

.media-grid__icon-container {
  display: inline-flex;
  align-items: center;
  height: 27px;
}

.media-grid__element-icon {
  margin-left: 0.25rem;
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  & g {
    stroke: var(--clr-blue) !important;
    stroke-width: 4 !important;
  }
}

.media-grid__image-container {
  height: 216px;
  width: auto;
  max-width: 100%;
  padding: 0.5rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-grid__image {
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
}

.media-grid__image-file {
  width: 64px;
  height: 64px;
  object-fit: contain;
  object-position: center;
}
