@use '../abstracts/mixins' as *;

// Adjusting for margin in main as its the only page without margin to footer
.login-page {
  margin-bottom: -8rem;
  height: 100%;
}

.login__container {
  display: flex;
  padding: 0;
  height: 100%;
}

.login__image-container {
  display: none;
  width: 100%;

  @include mq(small) {
    display: block;
  }
}

.login__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.login__login-container {
  width: 100%;
  background-color: var(--clr-gray-lightest);

  @include mq(small) {
    max-width: 450px;
  }
}

.login__login-wrapper {
  padding: 4rem 2rem 5rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--clr-gray-light);

  @include mq(small) {
    padding: 8rem 4rem 8rem 4rem;
  }
}

.login__logo-container {
  width: 148px;
  height: 148px;
  margin-bottom: 4rem;

  & svg {
    border-radius: 1.9rem;
    box-shadow: 0 2px 15px 0 rgb(0 0 0 / 10%);
  }
}

.login__headline {
  font-size: var(--fs-400);
  font-weight: 700;
  color: var(--clr-gray-dark);
  margin-bottom: 4rem;
  text-align: center;
  hyphens: none;

  &::after {
    display: none;
  }
}

.login__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4rem;
}

.login__form-inputs {
  margin-bottom: 1rem;
}

.login__form-label {
  display: flex;
  border: solid 1px #bdbdbd;
  position: relative;
  height: 3rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.5rem;

  &.top {
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: solid 1px #bdbdbd;
    margin-bottom: -1px;

    .login__form-input {
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }

  &.bottom {
    border-top: solid 1px #bdbdbd;
    border-radius: 0 0 0.5rem 0.5rem;
    margin-bottom: 1rem;

    .login__form-input {
      padding-right: 4rem;
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }
}

.login__form-label:focus-within {
  border: 2px solid var(--clr-blue);
  position: relative;
  z-index: 10;
}

.login__form-input-show {
  border: 0;
  outline: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  right: 1rem;
  padding: 0.25rem;
  top: 0;
  color: var(--clr-blue);
}

.login__form-input {
  border: 0;
  outline: 0;
  width: 100%;
  font-size: 1rem;
  padding: 0.25rem 1rem;
  background-color: #fff;
  color: var(--clr-gray-dark);
  @include transition(all);
}

.login__form-password-reset {
  color: var(--clr-blue);
  display: inline-block;
  text-decoration: none;
  position: relative;
  width: fit-content;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: var(--clr-blue);
    transition: width 0.3s;
    position: absolute;
    bottom: 1px;
  }

  &:hover::after {
    width: 100%;
    transition: width 0.3s;
  }
}

.login__button-container {
  display: flex;
  justify-content: center;
}

.login__submit-button {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  @include mq(tiny) {
    width: fit-content;
  }
}

.login__submit-button-svg {
  width: 20px;
  height: 20px;

  & g {
    stroke: white !important;
  }
}

.login__mail-link {
  display: flex;
  align-items: center;
  color: var(--clr-blue);
  width: fit-content;
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: var(--clr-blue);
    transition: width 0.3s;
    position: absolute;
    bottom: 1px;
  }

  &:hover::after {
    width: 100%;
    transition: width 0.3s;
  }
}

.login__mail-svg {
  width: 18px;
  height: 18px;
  margin-left: 0.25rem;

  & g {
    stroke: var(--clr-blue) !important;
  }
}
