@use '../abstracts/mixins' as *;

.outlet__order-specific-date {
  margin-top: 2.5rem;
}

.outlet__order-specific-date-label {
  font-weight: 700;
  margin-right: 0.35rem;
}
