@use '../abstracts/mixins' as *;
@use '../utilities/container';

.hamburger {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 2.125rem;
  margin-left: 1rem;
  padding: 0.25rem;

  &:focus {
    outline: 1px solid var(--clr-blue);
  }

  @include mq(medium) {
    display: none;
  }

  span {
    height: 0.1875rem;
    width: 100%;
    border-radius: 20px;
    background: var(--clr-gray-dark);
  }

  &::before,
  &::after {
    content: '';
    height: 0.1875rem;
    background: var(--clr-gray-dark);
    width: 100%;
    position: relative;
    transition: all ease 0.2s;
    border-radius: 20px;
  }

  &::before {
    left: 0;
  }

  &::after {
    top: 0;
    left: 0;
  }

  &:hover {
    & span {
      background: var(--clr-blue);
    }

    &::before,
    &::after {
      background: var(--clr-blue);
    }
  }
}
.nav--open + .hamburger {
  &::before {
    top: 0.55rem;
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
    top: -0.3rem;
  }
  span {
    opacity: 0;
  }
}
