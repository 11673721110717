@use '../../abstracts/mixins' as *;

.video__container video {
  width: 100%;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  max-width: 100%;
  height: auto;
}

video {
  max-width: 100%;
  height: auto;
}
