@use '../abstracts/mixins' as *;

.pasture-modal__overlay {
  background-color: rgba(0, 0, 0, 0.44);
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 10000;
  display: grid;
  place-items: center;
  overflow-y: auto;
}

.pasture-modal__content {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px';
  position: fixed;
  top: 50%;
  left: 50%;
  opacity: 1;
  transform: translate(-50%, -50%);
  min-width: 300px;
  width: 95%;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 1.5rem;

  @include mq(tiny) {
    width: 95%;
    min-width: 350px;
  }

  @include mq(small) {
    max-width: 600px;
    width: 100%;
    min-width: 400px;
  }

  @include mq(medium) {
    max-width: 700px;
    padding: 0 2.5rem;
    width: 100%;
    min-width: 500px;
  }
}

.pasture__modal-header {
  padding: 2.5rem 0 1rem 0;
  background-color: white;
  position: sticky;
  top: 0;
  display: flex;
  z-index: 40;
  justify-content: space-between;
}

.pasture-modal__close {
  background-color: transparent;
  border: 0;
  height: auto;
  padding: 0.25rem;
  cursor: pointer;
}

.pasture-modal__close-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.pasture-calendar__form-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid var(--clr-gray);
  gap: 0.75rem;
  flex-wrap: wrap;

  & .downshift-select {
    width: 50%;
    min-width: 250px;
    padding-top: 0;
  }

  @include mq(small) {
    flex-wrap: nowrap;
  }
}

.pasture-calendar__form-label {
  white-space: nowrap;
  font-weight: bold;
}

.pasture-calendar__form-label--break {
  margin-bottom: 1rem;
  font-weight: bold;
}

.pasture-calendar__form-question--label-top {
  padding: 1rem 0;
  border-bottom: 1px solid var(--clr-gray);

  & .pasture-calendar__form-plots {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;

    @include mq(small) {
      grid-template-columns: 1fr 1fr;
    }
  }

  & .pasture-calendar__form-plots-plot {
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }

  & .pasture-calendar__form-plot-name {
    max-width: 300px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.pasture-calendar__form-button-container {
  padding: 2rem 0;
  display: flex;
  justify-content: flex-end;
}

.pasture-calendar__inputs {
  display: flex;
  gap: 1rem 0.75rem;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  min-width: 300px;

  & .input {
    margin: 0;
    display: flex;
  }

  & .input__element {
    width: 160px;
  }

  @include mq(small) {
    width: 70%;
    justify-content: flex-end;
  }
}
