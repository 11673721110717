@use '../abstracts/mixins' as *;

.my-milk__month-headline {
  margin: 2rem 0 1.5rem 0;
}

.my-milk__other-pages-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

// My Milk Table Desktop
.my-milk__table {
  border: 0;
  width: 100%;
  white-space: nowrap;
  font-size: 1rem;
  table-layout: auto;
}

.my-milk__info-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.5rem;
}

.my-milk__data-addition {
  font-size: 1rem;
  margin-top: 1rem;
}

.my-milk__data-warning {
  font-size: 1rem;
}

.my-milk__table td {
  padding: 6px 4px;
  text-align: center;
  border-left: 0;
  border-right: 0;
  white-space: nowrap;

  @include mq(large) {
    width: 10%;
    padding: 6px 8px;
  }
}

.my-milk__table > thead > tr:first-child > th {
  white-space: normal;
  padding: 2px 4px;
  vertical-align: top;

  & sup {
    font-weight: bold;
    font-size: 0.875rem;
  }

  @include mq(large) {
    white-space: pre;
    padding: 6px 8px;
  }
}

// My Milk LabData Mobile
.my-milk__table-mobile {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: calc(100vw - 4rem);
  @include mq(medium) {
    max-width: calc(100vw - 4rem - 240px);
  }
}

.my-milk__table-mobile-header {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 150px;
  box-shadow: 0 0 9px 0 rgba(165, 165, 165, 0.5);
  z-index: 10;
  border-left: 2px solid var(--clr-gray-light);
  border-top: 1px solid var(--clr-gray-light);
}

.my-milk__table-mobile-header div {
  height: 36px;
  border-top: 1px solid var(--clr-gray-light);
  display: flex;
  align-items: center;
  padding: 0 6px;
}

.my-milk__table-mobile-body {
  display: block;
  left: 150px;
  overflow-x: auto;
  position: relative;
  margin-bottom: 24px;
  border-top: 1px solid var(--clr-gray-light);
  border-right: 1px solid var(--clr-gray-light);
  border-bottom: 2px solid var(--clr-gray-light);
  width: calc(100% - 150px);
}

.my-milk__table-mobile-entry {
  display: table-cell;
  white-space: nowrap;
  text-align: right;
}

.my-milk__table-mobile-entry div {
  padding: 5px 20px;
  height: 36px;
  border-top: 1px solid var(--clr-gray-light);
  border-right: 1px solid var(--clr-gray-light);
}

.my-milk-month__directions-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

// Warning Colors General
.freezing-blue {
  background-color: var(--clr-blue-light) !important;
}

.bacteria-yellow,
.cellcount-yellow {
  background-color: var(--clr-yellow-light) !important;
}

.bacteria-red,
.cellcount-red {
  background-color: var(--clr-red-light) !important;
}

.bacteria-yellow--dashboard,
.cellcount-yellow--dashboard {
  background-color: rgba(255, 243, 205, 0.35) !important;
  border: 1px solid var(--clr-yellow-light) !important;
}

.bacteria-red--dashboard,
.cellcount-red--dashboard {
  background-color: rgba(248, 215, 218, 0.3) !important;
  border: 1px solid var(--clr-red-light) !important;
}

// Warning Styles

.my-milk__warning {
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    margin-left: 0.25rem;
    height: 20px;
    width: 20px;
  }

  &.blue {
    color: var(--clr-blue-dark);
  }
  &.yellow {
    color: var(--clr-yellow-dark);
  }
  &.red {
    color: var(--clr-red-dark);
  }
}

// Charts
.chart__buttons-container {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 0.75rem 1.5rem;
}
