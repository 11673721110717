@use '../../abstracts/mixins' as *;

.lightboxstreamfield__title {
  margin-bottom: 1rem;
}

.lightboxstreamfield__description {
  margin-bottom: 1.5rem;
}

.lightbox__image-preview-container {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @include mq(small) {
    grid-template-columns: 1fr 1fr;
  }
}

.lightbox__image-preview-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
}

.lightbox__image-preview-image {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 300px;
  object-fit: cover;
  object-position: center;
  padding: 0.5rem !important;
  transition: 0.25s all;

  &:hover {
    cursor: pointer;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.4);
  }
}

.lightbox {
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10000 !important;
}

.lightbox-header {
  display: flex;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  color: var(--clr-blue);
  font-weight: 700;
  font-size: var(--fs-700);
}

.lightbox-header__button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
}

.lightbox-header__button {
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 0 1rem;
  display: flex;
  align-items: center;

  & svg {
    height: 36px;
    width: 36px;
    stroke: var(--clr-gray-dark);
  }
}

.lightbox-navigation-button__container {
  position: relative;
  z-index: 10001;
  height: 100%;
  display: flex;
}

.lightbox-direction__button {
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 0 1rem;
  display: flex;
  align-items: center;

  & svg {
    height: 64px;
    width: 64px;
    stroke: var(--clr-gray-dark);
  }
}

.lightbox__preview-title {
  text-align: center;
  color: var(--clr-gray-dark);
  font-weight: 700;
}

.lightbox__preview-item-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
}

.lightbox-header {
  color: var(--clr-gray-dark);
}
