@use '../../abstracts/mixins' as *;

.linklist__container {
  margin-top: 2rem;
}

.linklist__title {
  margin-bottom: 1rem;
}

.linklist__description {
  margin-bottom: 1.5rem;
}

.linklist__element-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.75rem 1rem 0;
  width: 100%;
  font-size: var(--fs-600);
  color: var(--clr-blue);
  text-decoration: none;
  font-weight: 700;

  &:hover {
    color: var(--clr-blue-dark);
  }
}

.linklist__element {
  border-bottom: 1px solid #ddd;

  &:first-child {
    border-top: 1px solid #ddd;
  }
}

.linklist__element-arrow {
  width: 32px;
  height: 32px;
  object-fit: contain;
  object-position: center;
  flex-shrink: 0;

  & path {
    stroke-width: 4 !important;
    stroke: var(--clr-blue);
  }
}

// EventList specific
.linklist__element-content-title {
  margin-right: 1rem;
}

.linklist__element-date-container {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: var(--fs-500);
  font-weight: 400;

  @include mq(medium) {
    width: auto;
    font-size: var(--fs-600);
    font-weight: 700;
  }
}

.linklist__element-date {
  margin-right: 0.5rem;
}

.linklist__element-event-content {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
