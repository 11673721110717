@use '../abstracts/mixins' as *;

.link-tiles__container {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  @include mq(small) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq(large) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.link-tile__image {
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  object-fit: cover;
  width: 100%;
  height: 150px;
}

.link-tile {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  text-decoration: none;
  transition: all 0.15s ease-in-out;

  &:hover {
    box-shadow: 0 2px 15px 0 rgb(0 0 0 / 20%);
  }
}

.link-tile__content {
  padding: 1.5rem;
}

.link-tile__description {
  color: var(--clr-gray-dark);
  margin-top: 1rem;
}
