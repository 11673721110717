@use '../abstracts/mixins' as *;
@use '../utilities/container';

.password-change {
  padding-top: 0;
}

.password-change__headline {
  margin: 3rem 0 2rem 0;
}

.password-change-form__buttons {
  margin: 2.5rem 0 4rem 0;
  display: flex;
  justify-content: space-between;
}

.password-change__requirements {
  margin-bottom: 3rem;
}
