@use '../../abstracts/mixins' as *;

.youtube-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.youtube-block__container {
  width: 100%;
  max-width: 800px;
  height: 300px;

  @include mq(tiny) {
    height: 350px;
  }

  @include mq(small) {
    height: 400px;
  }
}

.youtube-block__cover-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.youtube-block__cover-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: var(--clr-gray-light);
}

.youtube-block__cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.youtube-block__cover-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.75);
}

.youtube-block__main {
  width: 100%;
  height: 100%;
}

.youtube-block__video {
  height: 100%;
  width: 100%;
}

.youtube-block__button {
  margin-top: 1.5rem;
  & svg {
    height: 2rem;
    width: 2rem;
    margin-left: 0;
    object-fit: contain;
    object-position: center;
  }
}
