@use '../abstracts/mixins' as *;

.news-listing__list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @include mq(medium) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq(large) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.news__list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @include mq(medium) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq(large) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.news-listing__headline {
  margin-bottom: 1.25rem;
}
