@use '../../utilities/container';
@use '../../abstracts/mixins' as *;

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include mq(medium) {
    gap: 1em;
  }

  &__submit {
    width: max-content;
    margin-inline: auto;
    margin-top: 2rem;
  }
}

.help-text {
  margin-bottom: 1.25rem;
}
