@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/proxima-nova-regular.eot');
  src: url('../assets/fonts/proxima-nova-regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/proxima-nova-regular.woff2') format('woff2'),
    url('../assets/fonts/proxima-nova-regular.woff') format('woff'),
    url('../assets/fonts/proxima-nova-regular.ttf') format('truetype'),
    url('../assets/fonts/proxima-nova-regular.svg#proxima_novaregular') format('svg');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-display: swap;
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/proxima-nova-bold.eot');
  src: url('../assets/fonts/proxima-nova-bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/proxima-nova-bold.woff2') format('woff2'),
    url('../assets/fonts/proxima-nova-bold.woff') format('woff'),
    url('../assets/fonts/proxima-nova-bold.ttf') format('truetype'),
    url('../assets/fonts/proxima-nova-bold.svg#proxima_novabold') format('svg');
  font-weight: 700;
  font-style: normal;
}
