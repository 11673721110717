.event-form__headline {
  margin-bottom: 2.5rem;
}

.event-form__buttons {
  margin: 2.5rem 0 4rem 0;
}

.event-form__fields {
  & .event-form__input-amount {
    width: 180px;
  }
}
