@use '../abstracts/mixins' as *;
@use '../utilities/container';

.main {
  height: 100%;
  margin-bottom: 8rem;
  max-width: 100vw;
  overflow: hidden;

  @include mq(medium) {
    max-width: calc(100vw - 240px);
  }
}

.main--full-width {
  max-width: 100vw;

  @include mq(medium) {
    max-width: 100vw;
  }
}
