.login__back-link {
  padding-top: 2rem;
}

.login__back-container {
  width: min(100%, 73rem);
  margin: 0 auto;
  padding: 0 1rem;
}

.login__back {
  display: flex;
  align-items: center;
  color: var(--clr-blue);
  text-decoration: none;
  font-weight: 700;
  font-size: var(--fs-500);
  margin-bottom: 2rem;
}

.login__back-arrow {
  margin-right: 0.5rem;
}
