@use '../abstracts/mixins' as *;

.calendar-preview {
  padding: 2rem 0 2rem 0;
}

.calendar-preview__container {
  border-radius: 0.5rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
}

.calendar-preview__body {
  display: flex;
  flex-direction: column;

  @include mq(small) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    padding: 1.5rem 0;
  }
}

.calendar__preview-column {
  width: 100%;
  border: 0;
  padding: 1.5rem 1.5rem;
  border-bottom: 1px solid var(--clr-gray-light);

  &:last-child {
    border-bottom: 0;
  }

  @include mq(small) {
    display: flex;
    justify-content: flex-start;
    padding: 0 1.5rem;
    border-right: 1px solid var(--clr-gray-light);
    border-bottom: 0;

    &:last-child {
      border-right: 0;
    }
  }
}

.calendar-preview__appointment {
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.calendar-preview__appointment-headline {
  color: var(--clr-blue);
  font-weight: 700;
  text-decoration: none;
  hyphens: none;

  &:hover,
  &:focus,
  &:active {
    color: var(--clr-blue);
  }
}

.calendar-preview__appointment-datetime {
  color: var(--clr-gray-dark);
  font-size: var(--fs-400);
}

.calendar-preview__appointment-location {
  color: var(--clr-gray-dark);
  font-size: var(--fs-400);
}
