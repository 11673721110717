@use '../abstracts/mixins' as *;

.event-information__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.event-information__info {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-right: 1.5rem;
  font-weight: 700;
  font-size: var(--fs-500);

  &:last-child {
    margin-right: 0;
  }

  & svg {
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
    margin-right: 0.5rem;

    & g {
      stroke-width: 6 !important;
    }
  }
}
