@use '../abstracts' as *;

section {
  padding: 1rem 0 2rem 0;

  // @include mq(small) {
  //   padding: 3rem 0;
  // }
  // @include mq(medium) {
  //   padding: 3.5rem 0;
  // }
}

.columns {
  padding: 1rem 0 2rem 0;
}

.shadow {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
}

.shadow-padding {
  padding: 0.5rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
}
