@use '../abstracts/mixins' as *;

.button--primary {
  height: 3rem;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background-color: var(--clr-blue);
  color: var(--clr-white);
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: var(--fs-400);
  display: inline-flex;
  text-decoration: none;

  & svg {
    color: var(--clr-white);
    margin-left: 0.5rem;
  }

  &:hover,
  &:focus {
    background-color: var(--clr-blue-dark);
    color: var(--clr-white);
  }
}

.button--secondary {
  height: 3rem;
  padding: 0 2rem;
  text-decoration: none;
  border-radius: 1.5rem;
  background-color: var(--clr-blue-lightest);
  color: var(--clr-blue);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: var(--fs-400);

  & svg {
    color: var(--clr-blue);
    margin-left: 0.25rem;
  }

  &:hover {
    text-decoration: none;
    background-color: var(--clr-blue-light);
  }
}

.button--category {
  height: 2.5rem;
  padding: 0 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--clr-blue-lightest);
  color: var(--clr-blue);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: var(--fs-300);
  font-weight: 700;
  text-decoration: none;

  &-icon {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    object-position: center;
  }

  & svg {
    color: var(--clr-blue);
    margin-left: 0.25rem;
  }

  & svg.button--category-icon {
    margin-left: 0;
    margin-right: 0.25rem;
  }

  &.active {
    background-color: var(--clr-blue);
    color: var(--clr-white);

    & svg {
      color: var(--clr-white);
    }

    & svg.button--category-icon {
      & path,
      circle {
        stroke: white !important;
      }
    }

    &:hover {
      background-color: var(--clr-white);
      background-color: var(--clr-blue-dark);
    }
  }

  &:hover {
    background-color: var(--clr-blue-light);
  }

  &.disabled {
    background-color: var(--clr-gray-lightest);
    color: var(--clr-gray-dark);
    cursor: not-allowed;

    &:hover {
      background-color: var(--clr-gray-lightest);
      color: var(--clr-gray-dark);
    }
  }

  & sub {
    margin-bottom: 5px;
    align-self: flex-end;
  }
}

.button--delete {
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 0.5rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg {
    width: 1.35rem;
    height: 1.35rem;
    object-fit: contain;
    object-position: center;

    & g {
      stroke-width: 5 !important;
      stroke: var(--clr-red-dark) !important;
    }
  }
}

.button--edit {
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 0.5rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg {
    width: 1.35rem;
    height: 1.35rem;
    object-fit: contain;
    object-position: center;

    & g {
      stroke-width: 5 !important;
      stroke: var(--clr-gray-dark) !important;
    }
  }
}

.button__svg {
  display: flex;
  align-items: center;
}

.section-button {
  height: 3rem;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background-color: var(--clr-blue-lightest);
  color: var(--clr-blue);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: var(--fs-400);
  text-decoration: none;
  border: 1px solid var(--clr-blue);

  & svg {
    color: var(--clr-blue);
    margin-left: 0.25rem;
  }

  &:hover {
    background-color: var(--clr-blue-light-hover);
  }
}

.section-button__arrow {
  height: 2rem;
  width: auto;

  & path {
    stroke: var(--clr-blue);
  }
}

.button--icon svg {
  margin: 0;
}

.button--white {
  height: 2.5rem;
  padding: 0 2rem;
  border-radius: 0.5rem;
  background-color: white;
  color: var(--clr-gray-dark);
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: var(--fs-400);
  display: inline-flex;
  text-decoration: none;
  border: 1px solid #e5e5e5;
  font-weight: 700;
  transition: 0.3s all;

  & svg {
    color: var(--clr-gray-dark);
    margin-left: 0.5rem;
  }

  &:hover,
  &:focus {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  &.active {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--clr-gray-light);
  }
}

.button--chevron {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.5rem;
  background-color: var(--clr-white);
  color: var(--clr-gray-light);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  padding: 0;
  font-size: var(--fs-300);
  font-weight: 700;
  text-decoration: none;
  border: 1px solid var(--clr-gray);

  &-icon {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
    object-position: center;
  }

  & svg {
    color: var(--clr-gray-dark);
    stroke-width: 2px;
  }

  & svg.button--category-icon {
    margin-left: 0;
    margin-right: 0.25rem;
  }

  &:hover {
    background-color: var(--clr-blue-lightest);
    border-color: var(--clr-blue-light);

    & svg {
      color: var(--clr-blue);
    }
  }

  &.disabled {
    background-color: var(--clr-gray-lightest);
    border: 1px solid var(--clr-gray);
    color: var(--clr-gray-light);
    cursor: not-allowed;

    &:hover {
      & svg {
        color: var(--clr-gray-dark);
      }
    }
  }
}
