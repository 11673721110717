@use '../abstracts/mixins' as *;

.news-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--clr-white);
  border-radius: 0.5rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
  overflow: hidden;
  min-height: 200px;

  @include transition(all);

  &:last-child {
    margin-bottom: 0;
  }

  &__image-container {
    width: 100%;
    position: relative;
    height: 140px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 2.485 / 1;
  }

  &__content {
    padding: 2rem;
    width: 100%;
    color: var(--clr-gray-dark);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__headline {
    line-height: 1.25;
    margin-bottom: 0.75rem;
  }

  &__content-header {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
  }

  &__date {
    font-size: var(--fs-400);
    min-width: fit-content;
  }

  &__description {
    font-size: var(--fs-400);
    color: var(--clr-gray-dark);
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    gap: 0 0.5rem;
  }

  &__category {
    line-height: 1.5;
    color: var(--clr-blue);
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    flex-shrink: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    text-decoration: none;
  }
}
