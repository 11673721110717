.lexicon-entry {
  padding: 2rem 0;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.lexicon-entry__letter {
  font-size: 80px;
}

.lexicon-entry__title {
  font-weight: bold;
  font-size: 24px;
}

.lexicon-entry__text {
  font-size: 18px;
  color: var(--clr-gray-dark);
}

.lexicon-entry__not-found {
  padding: 3rem 0;
  font-size: var(--fs-500);
}

.lexicon-entry__link {
  text-decoration: none;
}

.lexicon-entry__container {
  margin-top: 4rem;

  &:first-child {
    margin-top: 3.5rem;
  }

  &:last-child {
    margin-bottom: 6rem;
  }
}
