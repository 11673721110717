@use '../abstracts/mixins' as *;

.plotmap-editor__file-chooser {
  opacity: 0;
  width: 100%;
  position: absolute;
  cursor: pointer;

  &::file-selector-button:hover {
    cursor: pointer;
  }
}

.plotmap-editor__file-chooser-label {
  position: relative;
  cursor: pointer;
}

.plotmap-editor__preview-image {
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: left;
  padding: 0.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
}

.plotmap-editor__buttons-container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;
}

.plotmap-editor__inner-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.plotmap-editor__notification-container {
  margin-top: 1.5rem;
}
