@use '../abstracts/mixins' as *;

.multiple-events-form {
  margin-bottom: 1rem;
}

.multiple-events-form__events {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 3rem;

  & .checkbox {
    align-items: start;
    padding-top: 7px;
  }
}

.multiple-events-form__event {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-areas:
    'checkbox label'
    '. input';
  grid-template-rows: auto;
  grid-gap: 0.25rem 1rem;
  align-items: center;

  & .multiple-events-form__event-particpant-amount-container {
    grid-area: input;

    & .input {
      max-width: 200px;
    }
  }

  @include mq(small) {
    grid-template-columns: 40px 4fr 4fr;
    grid-template-areas: 'checkbox label input';

    & .input {
      max-width: 200px;
    }
  }

  @include mq(medium) {
    grid-template-columns: 40px 3fr 4fr;
    grid-template-areas: 'checkbox label input';

    & .input {
      max-width: 200px;
    }
  }

  &.multiple-events-form__event--signed {
    grid-template-columns: 40px 1fr;
    grid-template-areas: unset;
  }
}

.multiple-events-form__event-info-timing {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.multiple-events-form__event-info-title {
  display: flex;
  gap: 0.5rem;
  font-weight: 700;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin-bottom: 0.25rem;
}

.multiple-events-form__event-registered {
  padding: 3px 10px;
  border-radius: 0.5rem;
  background-color: var(--clr-blue-light);
  color: var(--clr-blue);
  font-size: var(--fs-200);
  align-self: self-start;
}
