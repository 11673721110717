@use '../abstracts/mixins' as *;

.outlet__order-specific-grid-header {
  display: grid;
  grid-template-columns: 2fr 5fr 2fr 2fr 3fr;
  grid-gap: 1.5rem;
  padding: 1rem 0.5rem;
  font-weight: 700;
  border-bottom: 2px solid var(--clr-gray);
  justify-items: center;
}

.outlet__order-specific-article {
  display: grid;
  grid-template-columns: 2fr 5fr 2fr 2fr 3fr;
  grid-gap: 1.5rem;
  padding: 1rem 0.5rem;
  justify-items: center;

  background-color: var(--clr-gray-lightest);
  border-bottom: 1px solid var(--clr-gray);

  &:nth-child(2n) {
    background-color: white;
  }

  &:last-child {
    border-bottom: 2px solid var(--clr-gray);
  }
}

.outlet__order-specific-grid-total {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  font-weight: 700;
}

.outlet__order-specific-grid-total-label {
  margin-right: 0.5rem;
}

.outlet__order-specific-order-link a {
  text-decoration: none;
}
