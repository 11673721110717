@use '../abstracts/mixins' as *;

.outlet__order-specific-cards {
  border-top: 2px solid var(--clr-gray);
  border-bottom: 2px solid var(--clr-gray);
}

.outlet__order-specific-card-product-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.outlet__order-specific-card-product {
  padding: 1rem;
  border-bottom: 1px solid var(--clr-gray);
  background-color: var(--clr-gray-lightest);

  &:nth-child(2n) {
    background-color: white;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.outlet__order-specific-card-product-body {
  display: flex;
  align-items: center;
  gap: 0.5rem 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.outlet__order-specific-card-info {
  text-align: center;
  display: flex;

  & a {
    text-decoration: none;
  }
}

.outlet__order-specific-card-info-label {
  font-weight: 700;
  margin-right: 0.5rem;
}

.outlet__order-specific-cards-total {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  font-weight: 700;
}

.outlet__order-specific-cards-total-label {
  margin-right: 0.5rem;
}

.outlet__order-specific-card-image {
  height: 60px;
  width: 60px;
  object-fit: contain;
  object-position: center;
}
