.image {
  display: flex;
  justify-content: center;
  align-items: center;

  &--full-width {
    width: 100%;

    & .image__element {
      width: 100%;
      height: auto;
      object-fit: cover;

      &.shadow {
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
      }

      &.padding {
        padding: 0.5rem;
      }

      &.rounded {
        border-radius: 0.5rem;
      }
    }
  }
}

.image__element {
  height: auto;
  object-fit: contain;
  object-position: center;

  &.shadow {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  }

  &.padding {
    padding: 0.5rem;
  }

  &.rounded {
    border-radius: 0.5rem;
  }
}
