@use '../../abstracts/mixins' as *;
@use '../../utilities/container';

.checkbox {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  @include transition(all);

  &--baseline {
    align-items: flex-start;
  }

  &__root {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    background-color: white;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid #bdbdbd;
    cursor: pointer;
    flex-shrink: 0;
    box-sizing: border-box;
  }

  &__indicator {
    color: white;
    background-color: var(--clr-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    cursor: pointer;
    flex-shrink: 0;
  }

  &__label {
    color: var(--clr-gray-dark);
    padding: 0 0 0 0.5em;
    font-size: 1.125rem;
    line-height: 1.4;
    cursor: pointer;
    user-select: none;
    font-weight: lighter;

    &--small {
      font-size: clamp(1rem, 4vw, 1.5rem);
    }

    a {
      color: var(--clr-blue);
      display: inline-block;
      text-decoration: none;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 1px;
        background: var(--clr-blue);
        transition: width 0.3s;
        position: absolute;
        bottom: 1px;
      }

      &:hover::after {
        width: 100%;
        transition: width 0.3s;
      }

      &:hover,
      &:focus {
        color: var(--clr-blue);
      }
    }

    a[href*="//"]:not([href*="lisa.werkbank.de"]):not([href*="lisa.molkerei-ammerland.de"])
    {
      position: relative;
      margin-right: 1.1rem;
    }

    a[href^='mailto:'] {
      position: relative;
      margin-right: 1.8rem;
    }

    a[href^='tel:'] {
      position: relative;
      margin-right: 1.8rem;
    }

    a[href^='/media/'] {
      position: relative;
      margin-right: 1.8rem;
    }

    a[href*="//"]:not([href*="lisa.werkbank.de"]):not([href*="lisa.molkerei-ammerland.de"])::before
    {
      position: absolute;
      right: -1.5rem;
      top: calc(50% - 11px);
      content: ' ';
      width: 24px;
      height: 24px;
      background: url('/image-icons/arrow-right-up-blue.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    a[href^='mailto:']::before {
      position: absolute;
      right: -1.5rem;
      top: calc(50% - 9px);
      content: ' ';
      width: 18px;
      height: 18px;
      background: url('/image-icons/mail.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    a[href^='tel:']::before {
      position: absolute;
      right: -1.5rem;
      top: calc(50% - 9px);
      content: ' ';
      width: 18px;
      height: 18px;
      background: url('/image-icons/smartphone-blue.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    a[href^='/media/']::before {
      position: absolute;
      right: -1.5rem;
      top: calc(50% - 9px);
      content: ' ';
      width: 18px;
      height: 18px;
      background: url('/image-icons/file-blue.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
