@use '../abstracts/mixins' as *;

.kpm-pagination__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.kpm-pagination__button {
  border: 0;
  padding: 0.25rem 0.75rem;
  background-color: var(--clr-gray-light);
  border: 1px solid var(--clr-gray);
  border-left: 0;
  color: #6f9a3b;
  font-weight: bold;

  &.completed {
    background-color: rgba(111, 154, 59, 0.2);
  }

  &:first-child {
    border-left: 1px solid var(--clr-gray);
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    padding-left: 1rem;
  }

  &:last-child {
    border-left: 1px solid var(--clr-gray);
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding-right: 1rem;
  }
}

.kpm-pagination__button-label {
  padding: 0 0.5rem;
}

.kpm-pagination__button-label.active {
  color: white;
  background-color: #6f9a3b;
  border-radius: 9999px;
}
