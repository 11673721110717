@use '../abstracts/mixins' as *;

.kpm-question__headline {
  margin-bottom: 1rem;
}

.kpm-question__inputs {
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &.additional-data {
    flex-direction: row;
    gap: 0.5rem 1.5rem;
    flex-wrap: wrap;
  }

  & .input__element {
    max-width: 100px;
  }
}

.kpm-question__footprint-change {
  margin: 1.5rem 0;

  & b {
    font-size: var(--fs-600);
    color: var(--clr-blue);
  }
}

.kpm-question__previous {
  margin: 1rem 0 1rem 0;
  padding: 0.5rem;
  background-color: rgba(255, 243, 205, 0.35);
  border-radius: 0.5rem;
  display: inline-block;
}

.kpm-question__radio-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.kpm-question__radio-label {
  font-weight: bold;
}

.kpm-question__tooltip {
  text-decoration-style: dotted;
  text-decoration-line: underline;
}
