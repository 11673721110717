@use '../abstracts/mixins' as *;

.kpm-results__total-footprint {
  margin-top: 0.5rem;
  font-size: var(--fs-600);
  color: var(--clr-blue);
  font-weight: 700;
}

.kpm-results__total-footprint-headline {
  font-size: var(--fs-400);
}

.kpm-results__footprint-addition {
  margin-top: 1rem;
}

.kpm-results__mode-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.kpm-results__header {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--clr-gray);

  & sup {
    font-weight: 700;
  }
}

.kpm-results__body {
  background-color: var(--clr-gray-lightest);
}

.kpm-results__question {
  border-bottom: 1px solid var(--clr-gray);
  padding: 0.5rem 0.5rem;
  background-color: var(--clr-gray-lightest);

  &:nth-child(2n) {
    background-color: white;
  }
}

.kpm-results__question-content {
  display: grid;
  grid-template-columns: 20px 1fr 80px;
  grid-gap: 1rem;
}

.kpm-results__question-text {
  text-decoration: none;
  font-weight: 700;
  color: var(--clr-blue);
}

.kpm-results__question-footprint {
  display: flex;
  margin-right: 1rem;
  justify-content: flex-end;
  align-items: baseline;
  gap: 0.25rem;
}

.kpm-results__question-footprint-indicator {
  width: 10px;
  height: 10px;
  border-radius: 9999px;

  &.red {
    background-color: var(--clr-red);
  }

  &.yellow {
    background-color: var(--clr-orange);
  }

  &.green {
    background-color: var(--clr-green);
  }
}

.kpm-results__info-asterisk {
  font-weight: 700;
}

.kpm-results__info {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.kpm-results__info-indicators {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem 1rem;
  margin-left: 1rem;
}

.kpm-results__info-indicator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.kpm-results__canvas-container {
  aspect-ratio: 2;
  margin-top: 2rem; // Only added while buttons are disabled
}

.kpm-results__details-category-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 0.75rem 1.5rem;
}

.kpm-results__details-unit {
  margin-top: 2rem;
}

.kpm-results__completed {
  margin-top: 1rem;
}

.kpm-results__download-container {
  margin-top: 2rem;
}

@media print {
  .kpm-results__mode-selector {
    display: none;
  }

  .ergebnis-page .notification__section {
    display: none;
  }

  .kpm-results__download {
    display: none;
  }

  .kpm-results__notification {
    background-color: white;
    border: 0;
    padding: 0;
  }

  .kpm-results__question-footprint-indicator {
    display: none;
  }
}

.kpm-results__final {
  margin-top: 1rem;
}
