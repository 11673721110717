@use 'breakpoints' as *;

@mixin mq($key) {
  $size: map-get($breakpoints, $key);

  @media only screen and (min-width: $size) {
    @content;
  }
}

@mixin transition($property) {
  transition: $property 0.15s ease-in-out;
}
