.label {
  color: var(--clr-gray-dark);
  background-color: var(--clr-white);
  font-size: 0.75rem;
  font-weight: 700;
  user-select: none;
  position: absolute;
  top: -9px;
  left: 20px;
  padding: 0 0.5rem;
}
