@use '../abstracts/mixins' as *;

.home-page > :first-child {
  margin-top: 0;
}

.dashboard__section-title {
  margin-bottom: 1.5rem;
}

.dashboard__button-container {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.dashboard__button {
  box-sizing: border-box;
  height: 48px;
  padding: 0 2rem;
  border: 1px solid var(--clr-blue);
  border-radius: 1.5rem;
  background-color: var(--clr-blue-lightest);
  display: inline-flex;
  align-items: center;
  color: var(--clr-blue);
  cursor: pointer;
  transition: 0.15s all;
  text-decoration: none;

  & svg {
    margin-left: 0.5rem;
    width: 24px;
    height: 24px;

    & g {
      stroke: var(--clr-blue) !important;
    }
  }

  &:hover,
  &:focus {
    color: var(--clr-blue-dark);
    border: 1px solid var(--clr-blue-dark);
    text-decoration: none;

    & svg g {
      stroke: var(--clr-blue-dark) !important;
    }
  }
}
