@use '../abstracts/mixins' as *;
@use '../utilities/container';

.header {
  height: 4.5rem;
  border-bottom: 1px solid var(--clr-gray-light);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  transition: all 0.15s ease-in-out;
}

.header--scrolled {
  box-shadow: 0 8px 16px 0 rgba(128, 128, 128, 0.15);
}

.header__container {
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'logo nav';

  @include mq(small) {
    grid-template-columns: 80px 1fr 1fr;
    grid-template-areas: 'logo breadcrumb nav';
  }

  @include mq(medium) {
    grid-template-columns: 2fr 3fr 2fr;
    grid-template-areas: 'logo breadcrumb nav';
    margin: 0 auto;
    padding: 0 1rem;
    width: min(100% - 2rem, 69rem);
  }
}

.header__nav {
  justify-self: end;
  width: 100%;
  grid-area: nav;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header__logo-container {
  grid-area: logo;
  justify-self: start;
  align-self: center;
  display: flex;
  align-items: center;

  &:focus-visible {
    outline: 1px solid var(--clr-blue);
  }

  @include mq(medium) {
    display: none;
  }
}

.header__logo {
  width: 30px;
  height: 30px;
  object-position: center;
  object-fit: contain;
  border-radius: 0.4rem;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 10%);
}

.header__nav-element {
  padding: 0.75rem;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: white;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: var(--clr-gray-dark);
  align-items: center;
  font-size: var(--fs-200);
  font-weight: bold;
  margin-right: 0.25rem;
  white-space: nowrap;

  &:hover {
    color: var(--clr-blue);
  }

  &:focus-visible {
    outline: 1px solid var(--clr-blue);
  }

  &:last-child {
    margin-right: 0;
  }

  &.header__nav-element--mobile-hidden {
    display: none;

    @include mq(medium) {
      display: flex;
    }
  }

  .header__nav-element-text {
    display: none;

    @include mq(small) {
      display: inline;
    }
  }

  .header__nav-element-icon {
    height: auto;
    width: 24px;

    object-fit: contain;
    object-position: center;

    & g {
      stroke-width: 5 !important;
      stroke: var(--clr-gray-dark);
    }

    &.magnify {
      width: 24px;
    }

    &.globe {
      width: 20px;
    }
  }

  &:hover {
    .header__nav-element-icon g {
      stroke: var(--clr-blue-dark) !important;
    }
    .header__nav-element-text {
      color: var(--clr-blue-dark);
    }
  }
}

.header__nav-element-text {
  margin-left: 0.25rem;
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--clr-gray-dark);
}

.header__nav-element-dropdown-container {
  position: relative;
}

.header__nav-element-dropdown::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: -16px;
  left: 5.75rem;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 20px solid white;

  @include mq(medium) {
    left: 7.5rem;
  }
}

.header__nav-element-dropdown {
  display: none;
  position: absolute;
  border-radius: 0.5rem;
  top: 5rem;
  left: -4rem;
  padding: 1.125rem 1.5rem;
  background-color: white;
  width: 240px;
  z-index: 2000;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

  @include mq(medium) {
    left: -5.875rem;
  }

  &--show {
    display: block;
  }
}

.header__nav-element-dropdown-element {
  width: 100%;
  border-bottom: 1px solid var(--clr-gray-light);

  &:first-child {
    .header__nav-element-dropdown-link {
      padding-top: 0;
    }
  }

  &:last-child {
    border-bottom: 0;

    .header__nav-element-dropdown-link {
      padding-bottom: 0;
    }
  }
}

.header__nav-element-dropdown-link {
  background-color: transparent;
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  border: 0;
  font-weight: 700;
  width: 100%;
  height: 100%;
  text-align: left;
  text-decoration: none;
  outline: 0;
  color: var(--clr-gray-dark);

  & svg {
    margin-left: 0.5rem;
    width: 20px;
    height: 20px;

    & g {
      stroke-width: 5 !important;
      stroke: var(--clr-gray-dark) !important;
    }
  }

  &:hover {
    color: var(--clr-gray-dark);
    cursor: pointer;
  }
}

// Inbox specific
.header-nav__inbox {
  position: relative;
}

.header__nav-inbox-unread {
  position: absolute;
  top: 0.25rem;
  right: 0;
  background-color: var(--clr-red);
  color: white;
  padding: 0 0.25rem;
  font-size: 0.75rem;
  border-radius: 1rem;
}
