@use '../../abstracts/mixins' as *;
@use '../../utilities/container';

.input {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  max-width: 600px;

  &__label {
    color: var(--clr-gray-dark);
    background-color: var(--clr-white);
    font-size: 1.125rem;
    user-select: none;
    position: absolute;
    top: -14px;
    left: 20px;
    padding: 0 0.25rem;
  }

  &__element {
    border: 1px solid #bdbdbd;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    height: 3rem;
    // Adjustment accounting for label position;
    padding-left: 20px;
    font-size: 1rem;
    width: 100%;
    cursor: pointer;
    color: var(--clr-gray-dark);
    @include transition(all);

    &:focus {
      border: 2px solid var(--clr-blue);
      outline: none;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

.input--form {
  @extend .input;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 100%;

  &__element {
    max-width: 600px;
  }
}

.hidden-field .input .label {
  display: none;
}

.supplier-field {
  display: none;
}

// Inputs with units
.input__label--with-unit {
  display: flex;
  align-items: center;

  & .input {
    flex-grow: 1;
  }

  & .input__element {
    padding: 0.5rem 0.5rem;

    @include mq(small) {
      padding: 0.5rem 1rem;
    }
  }

  &.left {
    & .input__element {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    & span {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      border-right: 0;
    }
  }

  &.right {
    & .input__element {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    & span {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border-left: 0;
    }
  }
}

.input__label--with-unit span {
  height: 48px;
  padding: 0 0.5rem;
  min-width: max-content;
  border: 1px solid #bdbdbd;
  background-color: var(--clr-gray-lightest);
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq(small) {
    min-width: max-content;
    width: 3rem;
  }
}

// Disabling Number Up Down Buttons
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
