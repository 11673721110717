@use '../abstracts/mixins' as *;

.search__results {
  padding: 0;
}

.search__results-counter {
  font-size: var(--fs-500);
  padding: 2rem 0;
}

.search__results-counter-number {
  font-weight: 700;
}

.search__results-container {
  margin-bottom: 2rem;
}

.search__result-container {
  padding: 2rem 0;
  border-bottom: 1px solid #ddd;

  &:first-child {
    border-top: 1px solid #ddd;
  }
}

.search__result-link {
  text-decoration: none;
  color: var(--clr-gray-dark);

  &:hover {
    color: var(--clr-gray-dark);
  }
}

.search__result-found-link {
  color: var(--clr-blue);
}

.search__results-pagination {
  margin-top: 4rem;
}

.search__results__not-found {
  padding: 2rem 0;
  font-size: var(--fs-500);
}
