.event__buttons-container {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  gap: 0.75rem 1.5rem;
}

.event__ical-container {
  display: flex;
  justify-content: flex-end;
}

.event__ical-svg {
  width: 18px;
  height: 18px;
  object-fit: contain;
  object-position: center;

  & g {
    stroke: white !important;
    stroke-width: 5 !important;
  }
}

.event__registration-failed {
  margin: 2rem 0;
  font-weight: 700;
}
