@use '../abstracts/mixins' as *;

.outlet__backlink-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.outlet-back-link {
  display: flex;
  align-items: center;
  color: var(--clr-blue);
  text-decoration: none;
  font-weight: 700;
  font-size: var(--fs-500);
}

.menu-listing__cart-icon {
  width: 28px;
  height: 28px;

  & g {
    stroke-width: 4 !important;
    stroke: var(--clr-white) !important;
  }
}
