@use '../abstracts/mixins' as *;

.task-information {
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--rot {
    background-color: rgba(248, 215, 218, 0.3);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-red-light);
  }

  &--gelb {
    background-color: rgba(255, 243, 205, 0.35);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-yellow-light);
  }

  &--blau {
    background-color: rgba(232, 244, 252, 0.4);
    color: var(--clr-gray-dark);
    border: 1px solid var(--clr-blue-light);
  }

  &--weiss {
    background-color: white;
    border: 1px solid var(--clr-gray-light);
    color: var(--clr-gray-dark);
  }

  & h2 {
    margin-bottom: 1rem;
    font-size: var(--fs-700);
    color: inherit;
  }

  @include mq(small) {
    padding: 2.5rem;
  }
}

.task-info__shortdesc {
  margin-bottom: 1.5rem;
}

.task-info__key-word {
  margin-right: 0.2rem;
  font-weight: 700;
}

.task-info__link {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: all 0.15s ease-in-out;

  &:hover {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: inherit;
  }
}

.task-info__content {
  width: calc(70% - 1rem);
  @include mq(small) {
    width: calc(70% - 2rem);
  }
}

.task-info__image-container {
  height: 100%;
  display: flex;
  align-items: center;
  width: 30%;
}

.task-info__image {
  width: 100%;
  height: auto;
  max-height: 150px;
  object-fit: contain;
  object-position: center;
  display: flex;
  justify-content: flex-end;

  & svg {
    max-height: 100px;
  }
}
