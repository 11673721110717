@use '../abstracts/mixins' as *;

.pasture-calendar__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include mq(medium) {
    flex-direction: row;
  }
}

.pasture-calendar__calendar {
  width: 100%;

  @include mq(medium) {
    width: 50%;
  }
}

.pasture-calendar__day-detail {
  width: 100%;

  @include mq(medium) {
    width: 50%;
  }
}

.pasture-calendar__day-detail-header {
  & h2 {
    margin-bottom: 0.5rem;
  }
  margin-bottom: 2rem;
}

.pasture-calendar__day-detail-pasturage {
  padding: 1rem 0.5rem;
  border-top: 1px solid var(--clr-gray-light);

  &:last-child {
    border-bottom: 1px solid var(--clr-gray-light);
  }
}

.pasture-calendar__day-detail-pasturage-info {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.25rem;
  margin-bottom: 1rem;

  @include mq(tiny) {
    flex-direction: row;
  }
}

.pasture-calendar__day-detail-pasturage-other {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.pasture-calendar__day-detail-pasturage-group {
  font-weight: 700;
}

.pasture-calendar__day-detail-add-container {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.pasturage-calendar__pasturages-total {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.25rem 1rem;
  font-size: var(--fs-500);
  font-weight: 700;
  color: var(--clr-blue);
}

.pasturage-calendar__pasturages-total-groups {
  margin-top: 0.5rem;
  font-size: var(--fs-500);
  font-weight: 700;
  color: var(--clr-blue);
}

.pasturage-calendar__pasturages-total-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.25rem 1rem;
  width: 100%;
}

.pasture-calendar__day-detail-pasturage-buttons {
  display: flex;
  gap: 0.5rem;
}

.button--add-pasture {
  & span {
    display: flex;
    align-items: center;
  }

  & svg {
    height: 24px;
    width: 24px;
    object-fit: contain;
  }
}

.tile__greendot {
  position: relative;

  &::after {
    position: absolute;
    bottom: 0.35rem;
    left: calc(50% - 5px);
    width: 12px;
    height: 12px;
    content: '';
    background-image: url('/pasture-calendar/calender-check.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.tile__reddot {
  position: relative;

  &::after {
    position: absolute;
    bottom: 0.35rem;
    left: calc(50% - 5px);
    width: 12px;
    height: 12px;
    content: '';
    background-image: url('/pasture-calendar/calender-error.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.pasture-calendar__milkingtimes-hint {
  margin-top: 1.5rem;
}

.pasture-calendar__checkmark-hint {
  margin-top: 1.5rem;
}

.pasture-calendar__checkmark-hint-info {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  & svg {
    width: 16px;
    height: 16px;
  }
}

.pasture-calendar__pasture-duration {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  & svg {
    width: 16px;
    height: 16px;
  }
}

// All pasturage sites
.pasturages__other-pages-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
