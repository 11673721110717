@use '../../abstracts/mixins' as *;

.richtext {
  a {
    word-break: break-word;
  }

  p {
    margin-bottom: 0.75rem;
  }

  h2,
  h3,
  h4 {
    margin-bottom: 1rem;
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }
  }

  h4,
  h5,
  h6 {
    margin: 1rem 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.richtext-image {
  object-fit: contain;
  object-position: center;
  height: auto;

  &.left {
    @include mq(medium) {
      float: left;
      padding-right: 2rem;
      padding-bottom: 1rem;
      clear: left;
    }
  }

  &.right {
    @include mq(medium) {
      float: right;
      padding-left: 2rem;
      padding-bottom: 1rem;
      clear: right;
    }
  }

  &.small {
    width: 25%;
  }

  &.large {
    width: 50%;
  }

  &.x-large {
    width: 75%;
  }

  &.full-width {
    width: 100%;
  }

  &.thumbnail {
    padding: 0.5rem;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  }
}
