@use '../../abstracts/mixins' as *;

.timeline__title {
  margin-bottom: 1rem;
}

.timeline__description {
  margin-bottom: 1.5rem;
}

.timeline__wrapper {
  position: relative;
  max-width: 1200px;
  margin: auto 0 0 0;

  &::after {
    content: '';
    position: absolute;
    width: 4px;
    background-color: var(--clr-blue);
    top: 2rem;
    bottom: 2rem;
    left: 0;
    margin-left: -3px;
  }
}

.timeline__element {
  padding: 2rem;
  position: relative;
  background-color: #f4f4f4;
  width: 95%;
  left: 0;
  margin-left: 1.5rem;
  margin-top: 1rem;
  border-radius: 0.5rem;

  &::after {
    content: '';
    position: absolute;
    width: 19px;
    height: 19px;
    left: -34px;
    background-color: var(--clr-blue);
    top: 50%;
    border-radius: 50%;
    z-index: 1;
  }

  &::before {
    content: ' ';
    height: 0;
    position: absolute;
    top: 50%;
    width: 0;
    z-index: 1;
    left: -10px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent #f4f4f4 transparent transparent;
  }
}

.timeline__element-body {
  display: flex;
  justify-content: space-between;
}

.timeline__element-date {
  color: var(--clr-blue);
  font-size: var(--fs-600);
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.timeline__element-title {
  margin-bottom: 1rem;
  font-size: var(--fs-400);
  color: var(--clr-gray-dark);
  line-height: 1.2;
}

.timeline__element-content {
  width: 100%;
  @include mq(medium) {
    width: calc(60% - 1rem);
  }
}

.timeline__element-image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;

  @include mq(medium) {
    width: 40%;
    justify-content: flex-end;
    margin: 0;
  }
}

.timeline__element-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  max-width: 400px;
  object-fit: contain;
  object-position: center;

  @include mq(medium) {
    object-position: right;
  }
}
