@use '../abstracts/mixins' as *;

.outlet-article__information-container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;

  .outlet-article__image-container {
    width: 100%;
  }

  @include mq(small) {
    flex-wrap: nowrap;

    & .outlet-article__image-container {
      max-width: 50%;
    }
  }
}

.outlet-article__image {
  width: 100%;
  height: auto;
  max-height: 250px;
  object-fit: contain;

  @include mq(small) {
    max-height: 320px;
  }
}

.outlet-article__short-desc {
  margin-bottom: 1rem;
}

.outlet-article__info {
  margin-bottom: 1rem;
}

.outlet-article__prices {
  font-weight: 700;
  padding: 1rem 0;
}

.outlet-article__price {
  font-size: 1.625rem;
  margin-bottom: 0.5rem;
}

.outlet-article__price-ust {
  font-size: var(--fs-300);
  margin-left: 0.5rem;
}

.outlet-article__price-per-unit {
  margin-bottom: 0.25rem;
}

.outlet-article__amount {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.outlet-article__amount input {
  width: 100px;
}

.outlet-article__order-form {
  display: flex;
  gap: 1rem 2rem;
  align-items: center;
  flex-wrap: wrap;
}

.outlet-article__variants {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.outlet-article__submit--disabled {
  background-color: var(--clr-gray-light);
  color: var(--clr-gray-darkest);
  cursor: not-allowed;

  &:hover {
    background-color: var(--clr-gray-light);
    color: var(--clr-gray-darkest);
    cursor: not-allowed;
  }
}

.outlet-article__input--disabled {
  background-color: var(--clr-gray-lightest);
  color: var(--clr-gray-darkest);
  cursor: not-allowed;

  &:hover {
    background-color: var(--clr-gray-lightest);
    color: var(--clr-gray-darkest);
    cursor: not-allowed;
  }
}
