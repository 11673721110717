@use '../../abstracts/mixins' as *;

.contact-block__contact-content {
  display: grid;
  margin-top: 2.5rem;
  grid-template-columns: 1fr;
  grid-row-gap: 1.25rem;
  grid-template-areas:
    'headline'
    'image'
    'description';

  &:first-child {
    margin-top: 0;
  }

  @include mq(small) {
    grid-template-areas:
      'image headline'
      'image description';
    grid-template-rows: auto 1fr;
    grid-column-gap: 2.5rem;
    grid-row-gap: 0.75rem;
    grid-template-columns: 216px 1fr;
  }
}

.contact-block--no-images .contact-block__contact-content {
  grid-template-columns: 1fr;
  grid-template-areas:
    'headline'
    'description';
}

.contact-block__contact-name {
  grid-area: headline;
  font-size: var(--fs-500);
  color: var(--clr-gray-dark);
}

.contact-block__contact-info {
  grid-area: description;
}

.contact-block__contact-image-container {
  display: flex;
  height: 216px;
  width: 216px;
  align-items: center;
  justify-content: center;
  grid-area: image;
  padding: 0.5rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.contact-block__contact-image {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}

.contact-block__contact-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 3.5rem;

  .contact-block__contact-content {
    margin-top: 0;
  }

  @include mq(large) {
    grid-template-columns: 1fr 1fr;
  }
}
