@use '../abstracts/mixins' as *;

.outlet__order-conditions-list {
  margin-bottom: 2rem;
}

.outlet__order-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  gap: 1.5rem 1rem;
}

.outlet__cart-empty {
  padding: 3rem 2rem 5rem 2rem;
}

.outlet_accept-agb-req {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
