.textarea {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  max-width: 600px;
  padding: 0.1rem;

  &__label {
    color: var(--clr-gray-dark);
    background-color: var(--clr-white);
    font-size: 1.125rem;
    user-select: none;
    position: absolute;
    top: -14px;
    left: 20px;
    padding: 0 0.25rem;
  }

  .textarea__element {
    border: 1px solid #bdbdbd;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    // Adjustment accounting for label position;
    padding-left: 20px;
    font-size: 1rem;
    width: 100%;
    cursor: pointer;
    color: var(--clr-gray-dark);
    min-height: 150px;
    max-width: 600px;

    &:focus {
      border: 2px solid var(--clr-blue);
      outline: none;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

.textarea.kpm {
  width: 100%;
  max-width: 100%;

  & label {
    font-size: var(--fs-500);
    color: var(--clr-blue);
    font-weight: bold;
  }

  & textarea {
    font-size: var(--fs-400);
    font-weight: 400;
    color: var(--clr-gray-dark);
    margin-top: 1rem;
    max-width: 100%;
    min-height: 80px;
  }
}

.textarea--form {
  @extend .textarea;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  & .textarea__element {
    min-height: 150px;
    max-width: 100%;
  }
}
