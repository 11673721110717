@use '../abstracts/mixins' as *;

.outlet__order-cards {
  border-bottom: 2px solid var(--clr-gray);
  border-top: 2px solid var(--clr-gray);
}

.outlet__order-card {
  padding: 1rem 0.5rem;

  background-color: var(--clr-gray-lightest);

  &:nth-child(2n) {
    background-color: white;
  }
}

.outlet__order-card-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  justify-items: center;
  align-items: center;

  &:first-child {
    margin-bottom: 0.5rem;
  }
}

.outlet__order-card-label {
  font-weight: 700;
  margin-right: 0.25rem;
  text-align: center;
}

.outlet__order-card-order-info {
  text-align: center;

  & a {
    text-decoration: none;
    text-align: center;
  }
}
