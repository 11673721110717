@use '../abstracts/mixins' as *;

.pagination__container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 4.5rem;

  .pagination__button {
    width: 36px;
    height: 36px;
    margin: auto 0.25rem;
    color: var(--clr-gray-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 1px solid #ddd;
    background-color: var(--clr-white);

    &:hover {
      background-color: rgba(0, 0, 0, 0.025);
      cursor: pointer;
    }

    &.disabled {
      pointer-events: none;
      background-color: #f4f4f4;
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
        cursor: not-allowed;
      }
    }

    & svg {
      color: var(--clr-gray-dark);
    }
  }
}

.pagination__index {
  margin: 0 0.75rem;
  display: flex;
  align-items: center;
}
