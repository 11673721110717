@use '../abstracts/mixins' as *;
@use '../utilities/container';

.navigation {
  font-size: var(--fs-300);
  @include mq(medium) {
    display: block;
    min-width: 240px;
    width: 240px;
    min-height: 100%;
    background-color: var(--clr-gray-nav);
    border-right: 1px solid var(--clr-gray-light);
    position: relative;
    z-index: 2000;
  }
}

.navigation__nav-container {
  padding-bottom: 2rem;
}

.navigation__wrapper {
  position: sticky;
  top: 0;
  z-index: 950;
}

.navigation__logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 41px 1rem;
  text-decoration: none;
}

.navigation__logo {
  height: 60px;
  width: 60px;
  object-fit: contain;
  object-position: center;
  border-radius: 0.9rem;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 10%);
}

.navigation__logo-text {
  text-align: center;
  margin-top: 1rem;
  font-weight: 700;
  color: var(--clr-gray-dark);
  hyphens: none;
  font-size: var(--fs-200);
}

.navigation__element-container {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid var(--clr-gray-light);
  border-top: 1px transparent;

  &:first-child {
    border-top: 1px solid var(--clr-gray-light);
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    .navigation__element {
      color: var(--clr-blue);
      background-color: var(--clr-white);
      border-color: var(--clr-gray-light);
    }
  }
}

.navigation__element {
  @include transition(all);
  padding: 11px 12px 11px 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: var(--clr-gray-dark);
  font-weight: 700;

  &.active {
    background-color: var(--clr-white);
    color: var(--clr-blue);
  }
}

.navigation__element-container:hover,
.navigation__element-container:focus,
.navigation__element-container:focus-within {
  .navigation__subnav-container {
    display: block;
    position: absolute;
    z-index: 950;
    left: 240px;
    top: -1px;
    width: 320px;
    background-color: var(--clr-gray-nav);
  }
}

.navigation__subnav-container {
  display: none;
}

.navigation__subnav-element {
  padding: 11px 12px 11px 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: var(--clr-gray-dark);
  font-weight: 700;

  &.active {
    background-color: var(--clr-white);
    color: var(--clr-blue);
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--clr-blue);
  }
}

.navigation__subnav-element-container {
  background-color: var(--clr-gray-nav);
  border-bottom: 1px solid var(--clr-gray-light);
  border-top: 1px transparent;
  border-right: 1px solid var(--clr-gray-light);

  &:first-child {
    border-top: 1px solid var(--clr-gray-light);
  }

  cursor: pointer;
  position: relative;

  .navigation__subnav-container.layer2 {
    display: none;
  }
}

.navigation__subnav-element-container:hover,
.navigation__subnav-element-container:focus,
.navigation__subnav-element-container:focus-within {
  .navigation__subnav-element {
    color: var(--clr-blue);
    background-color: var(--clr-white);
  }

  .navigation__subnav-container.layer2 {
    display: block;
    position: absolute;
    z-index: 950;
    left: 320px;
    top: -1px;
    width: 320px;
    background-color: var(--clr-gray-nav);
  }
}

.navigation__subnav-element-layer2 {
  padding: 11px 12px 11px 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: var(--clr-gray-dark);
  font-weight: 700;

  &.active {
    background-color: var(--clr-white);
    color: var(--clr-blue);
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--clr-blue);
  }
}

.navigation__subnav-element-container-layer2 {
  border-bottom: 1px solid var(--clr-gray-light);
  border-top: 1px transparent;
  border-right: 1px solid var(--clr-gray-light);

  &:first-child {
    border-top: 1px solid var(--clr-gray-light);
  }
}

.navigation__subnav-element-container-layer2:hover,
.navigation__subnav-element-container-layer2:focus,
.navigation__subnav-element-container-layer2:focus-within {
  .navigation__subnav-element-layer2 {
    color: var(--clr-blue);
    background-color: var(--clr-white);
  }
}

.navigation__has-subnav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.navigation__subnav-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
}
