@use '../abstracts/mixins' as *;

.react-datepicker {
  border: 1px solid var(--clr-gray) !important;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
}

// React Datepicker Custom Styles
.react-datepicker__input-container input {
  border-radius: 0.5rem;
  height: 2.5rem;
  padding: 0 1rem;
  color: var(--clr-gray-darkest);
  border: 1px solid var(--clr-gray);
}

.react-datepicker-popper {
  z-index: 100 !important;
  color: var(--clr-gray-darkest) !important;
}

.react-datepicker__header {
  background-color: var(--clr-gray-lightest) !important;
  border-bottom: 1px solid var(--clr-gray) !important;
}

.react-datepicker__navigation-icon--next::before {
  top: 13px !important;
  border-color: var(--clr-blue) !important;
}

.react-datepicker__navigation-icon--previous::before {
  top: 13px !important;
  border-color: var(--clr-blue) !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-bottom-color: var(--clr-gray-lightest) !important;
}

.react-datepicker__month-text {
  padding: 0.5rem 0;
}

.react-datepicker__month-text--selected {
  background-color: var(--clr-blue-light) !important;
}

.react-datepicker__year-text {
  padding: 0.5rem 0;
}

.react-datepicker__year-text--selected {
  background-color: var(--clr-blue-light) !important;
  color: var(--clr-gray-darkest) !important;
}
