@use '../abstracts/mixins' as *;

.outlet__order-grid-header {
  display: grid;
  grid-template-columns: 2fr 6fr 2fr 4fr;
  grid-gap: 1.5rem;
  padding: 1rem 0.5rem;
  font-weight: 700;
  border-bottom: 2px solid var(--clr-gray);
  justify-items: center;
}

.outlet__order-grid-order {
  display: grid;
  grid-template-columns: 2fr 6fr 2fr 4fr;
  grid-gap: 1.5rem;
  align-items: center;
  padding: 1rem 0.5rem;
  justify-items: center;

  border-bottom: 1px solid var(--clr-gray);
  background-color: var(--clr-gray-lightest);

  &:nth-child(2n) {
    background-color: white;
  }

  &:last-child {
    border-bottom: 2px solid var(--clr-gray);
  }
}

.outlet__order-grid-order-link a {
  text-decoration: none;
}
