@use '../../abstracts/mixins' as *;

.columns__container {
  display: grid;
  grid-column-gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @include mq(small) {
    grid-template-columns: 1fr 1fr;
  }
}

.columns__description {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
