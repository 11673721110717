@use '../abstracts/mixins' as *;

.kpm-additional-calc {
  margin: 3rem 0;

  & label {
    font-size: var(--fs-300);
    font-weight: 700;
    display: inline-block;
  }

  & .input__element,
  label div {
    font-weight: 400;
  }
}

.kpm-additional-calc__input label {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem 2rem;
  align-items: center;

  @include mq(small) {
    grid-template-columns: 150px 1fr;
  }
}

.kpm-additional-calc__input.wide label {
  @include mq(small) {
    grid-template-columns: 1fr;
  }

  @include mq(medium) {
    grid-template-columns: 450px 1fr;
  }
}

.kpm-additional-calc__result label {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem 2rem;
  align-items: center;

  @include mq(small) {
    grid-template-columns: 150px 1fr;
  }

  @include mq(medium) {
    grid-template-columns: 150px 1fr;
  }
}

.kpm-additional-calc__result.wide label {
  @include mq(small) {
    grid-template-columns: 1fr;
  }

  @include mq(medium) {
    grid-template-columns: 450px 1fr;
  }
}

.kpm-additional-calc__headline {
  margin-bottom: 2rem;
}

.kpm-additional-calc__input .input__element {
  max-width: 100px;
  min-width: 60px;
}

.kpm-additional-calc__result .input__element {
  max-width: 100px;
  min-width: 60px;
}

.kpm-additional-calc__result {
  margin-top: 1.5rem;
}

.kpm-additional-calc__inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.kpm-additional-calc__functional-container {
  margin-top: 2rem;
}

.kpm-additional-calc__button-container {
  display: flex;
  justify-content: flex-end;
  max-width: 400px;
}

.kpm-additional-calc__add-button {
  width: fit-content;
  margin-bottom: 3rem;
}

.kpm-additional-calc__inputs-grid {
  display: grid;
  grid-gap: 1rem 2rem;
  grid-template-columns: 1fr;
  align-items: center;
  margin-bottom: 2rem;

  & label {
    margin-top: 1.5rem;

    @include mq(medium) {
      margin-top: 0;
    }
  }

  @include mq(medium) {
    grid-template-columns: 150px auto 1fr;
  }
}

.kpm-additional-calc__grid-headline {
  color: var(--clr-blue);
  font-weight: 700;
  align-self: normal;

  &.single {
    margin-bottom: 1rem;
  }
}

.kpm-additional-calc__inputs-grid--large {
  display: grid;
  grid-gap: 1rem 2rem;
  grid-template-columns: 1fr;
  align-items: center;
  margin-bottom: 2rem;

  @include mq(medium) {
    grid-template-columns: 160px auto auto 1fr;
  }
}
