@use '../abstracts/mixins' as *;

.pasture-editor__container {
  display: grid;
  grid-template-columns: 1fr;
}

.pasture-editor__header {
  display: grid;
  grid-template-columns: 8fr 1fr 1fr;
  grid-gap: 0.75rem;
  align-items: center;

  @include mq(small) {
    grid-gap: 2rem;
    grid-template-columns: 8fr 1fr 1fr;
  }
}

.pasture-editor__header-enable {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  margin-left: 1.5rem;
}

.pasture-editor__group {
  display: grid;
  grid-template-columns: 4fr 3fr 1fr 1fr;
  grid-gap: 0.75rem;
  border-bottom: 1px solid var(--clr-gray-light);
  align-items: center;

  @include mq(small) {
    grid-gap: 2rem;
    grid-template-columns: 6fr 2fr 1fr 1fr;
  }
}

.pasture-editor__button-container {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;

  & .button--secondary svg {
    width: 24px;
    height: 24px;
    stroke-width: 3;
  }
}

.pasture-editor__group-enabled {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pasture-editor__group-delete {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pasture-editor__notice-container {
  margin-top: 1.5rem;
}
