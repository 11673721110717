@use '../abstracts/mixins' as *;

.inbox-overview {
  padding: 1rem 0 2rem 0;
  margin-top: 3rem;
}

.inbox-overview__container {
  display: grid;
  grid-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.inbox-overview__message {
  background-color: var(--clr-gray-nav);
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 2.25rem 1.5rem;
  text-decoration: none;
  color: var(--clr-gray-dark);
  transition: all 0.15s ease-in-out;
  font-size: var(--fs-400);
}

.inbox-overview__message-date {
  margin-bottom: 0.5rem;
}

.inbox-overview__message-headline {
  margin-bottom: 1rem;
  font-size: var(--fs-650);
}
