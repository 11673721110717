@use '../abstracts/mixins' as *;

.milkingtimes-editor__group {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-bottom: 3rem;

  @include mq(medium) {
    grid-template-columns: 1fr 3fr;
    grid-gap: 2rem;
  }
}

.milkingtimes-editor__label {
  font-weight: 700;
}

.milkingtimes-editor__checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.milkingtimes-inputs {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  @include mq(small) {
    gap: 1.5rem;
  }
}

.time-svg {
  width: 20px;
  height: 20px;
  object-fit: contain;

  & g {
    stroke-width: 5 !important;
  }
}

.milkingtimes-editor__input-group input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.milkingtimes-editor__input-group {
  display: grid;

  grid-template-areas:
    'headline  headline'
    'input1  delete'
    'input2  delete';
  grid-template-columns: 200px 50px;
  align-items: center;
  grid-template-rows: auto;
  grid-gap: 1.5rem;

  @include mq(tiny) {
    grid-template-areas:
      'headline headline headline'
      'input1 input2 delete';
    grid-template-columns: 150px 150px 50px;
  }

  @include mq(small) {
    grid-template-columns: 100px 180px 180px 50px;
    grid-template-areas: 'headline input1 input2 delete';
  }
}

.milkingtimes-editor__input-group-name {
  grid-area: headline;
}

.milkingtimes-editor__delete-container {
  grid-area: delete;
  display: flex;
  align-items: center;
}

.milkingtimes-editor__input-1 {
  grid-area: input1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;

  @include mq(small) {
    justify-content: center;
  }

  & .input__element {
    max-width: 120px;
  }
}

.milkingtimes-editor__input-2 {
  grid-area: input2;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;

  @include mq(small) {
    justify-content: center;
  }

  & .input__element {
    max-width: 120px;
  }
}

.milkingtimes-editor__button-container {
  display: flex;
  justify-content: flex-end;
}
