@use '../../abstracts/mixins' as *;

.accordion__title {
  margin-bottom: 1rem;
}

.accordion__description {
  margin-bottom: 1.5rem;
}

.accordion__header {
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  z-index: 1;
}

.accordion__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.accordion__element {
  margin-bottom: 1rem;
  width: 100%;

  & .h3 {
    text-align: left;
  }
}

.accordion__element-trigger {
  background-color: var(--clr-gray-nav);
  border: none;
  padding: 1rem;
  width: 100%;
  border: 1px solid var(--clr-gray-light);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq(small) {
    padding: 1rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.accordion__element-icon--open {
  display: none;
  color: var(--clr-blue);
  height: 24px;
  width: 24px;
}

.accordion__element-icon--closed {
  display: block;
  color: var(--clr-blue);
  height: 24px;
  width: 24px;
}

.accordion__element[data-state='open'] {
  & .accordion__element-icon--open {
    display: block;
  }
  & .accordion__element-icon--closed {
    display: none;
  }
}

.accordion__element-content {
  border-radius: 0 0 8px 8px;
  padding: 0 1rem;
  background-color: var(--clr-white);
  overflow: hidden;

  @include mq(small) {
    padding: 0 1.5rem;
  }
}

.accordion__element-content > *:first-child {
  margin-top: 1rem;

  @include mq(small) {
    margin-top: 1.5rem;
  }
}

// Contact Accordion

.accordion__contact-content {
  margin-top: 1.5rem;
  grid-template-columns: 1fr;

  @include mq(small) {
    display: grid;
    grid-template-areas: 'image main';
    grid-template-columns: 212px 1fr;
    grid-gap: 2.5rem;
  }

  &:first-child {
    margin-top: 1.5rem;
  }

  &:last-child {
    margin-bottom: 1.5rem;
  }
}

.accordion__contact-name {
  font-size: var(--fs-500);
  color: var(--clr-gray-dark);
  margin-bottom: 0.75rem;
}

.accordion__contact-information {
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
}

.accordion__contact-subjectarea {
  font-weight: 700;
}

.accordion__contact-info {
  @include mq(small) {
    grid-area: main;
  }
}

.accordion__contact-image-container {
  display: none;

  @include mq(small) {
    display: flex;
    align-items: center;
    height: 212px;
    grid-area: image;
    padding: 0.5rem;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  }
}

.accordion__contact-image {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}

.accordion__contact-link {
  display: flex;
  align-items: center;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: all 0.15s ease-in-out;
  margin-left: 0.2rem;

  &:hover,
  &:focus {
    text-decoration: underline;
    text-decoration-color: var(--clr-blue);
  }
}

.accordion__contact-svg {
  height: 18px;
  width: auto;
  object-fit: contain;
  object-position: center;
  margin-left: 0.25rem;

  & g {
    stroke: var(--clr-blue) !important;
  }
}

// Transition stuff

.accordion__element-content[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
  // animation-fill-mode: forwards; this was a fix for weird flickering in animation, caused other problems
}

.accordion__element-content[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
  // animation-fill-mode: forwards; this was a fix for weird flickering in animation, caused other problems
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
