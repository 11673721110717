@use '../abstracts/mixins' as *;

.profile {
  margin-bottom: 6rem;
}

.profile__headline {
  margin-bottom: 3rem;

  @include mq(md) {
    margin-bottom: 4rem;
  }
}
