@use '../../abstracts/mixins' as *;

$unchecked-star: '\2606';
$unchecked-color: #e1e1e1;
$checked-star: '\2605';
$checked-color: #f9b55c;

.star-cb-group {
  /* remove inline-block whitespace */
  font-size: 0;
  * {
    font-size: 32px;
    font-family: Helvetica, arial, sans-serif;
  }
  /* flip the order so we can use the + and ~ combinators */
  unicode-bidi: bidi-override;
  direction: rtl;
  width: max-content;
  & > input {
    display: none;
    & + label {
      /* only enough room for the star */
      display: inline-block;
      overflow: hidden;
      text-indent: 9999px;
      width: 32px;
      white-space: nowrap;
      cursor: pointer;
      &:before {
        display: inline-block;
        text-indent: -9999px;
        // content: $unchecked-star;
        content: $checked-star;
        color: $unchecked-color;
      }
    }
    &:checked ~ label:before,
    & + label:hover ~ label:before,
    & + label:hover:before {
      content: $checked-star;
      color: $checked-color;
      //   text-shadow: 0 0 1px #333;
    }
  }

  /* the hidden clearer */
  & > .star-cb-clear + label {
    text-indent: -9999px;
    width: 0.5em;
    margin-left: -0.5em;
  }
  & > .star-cb-clear + label:before {
    width: 0.5em;
  }

  &:hover > input + label:before {
    // content: $unchecked-star;
    content: $checked-star;
    color: $unchecked-color;
    text-shadow: none;
    transition: all 0.15s;
  }
  &:hover > input + label:hover ~ label:before,
  &:hover > input + label:hover:before {
    content: $checked-star;
    color: $checked-color;
    // text-shadow: 0 0 1px #333;
  }
}
