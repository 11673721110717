@use '../../abstracts/mixins' as *;

.cards__title {
  margin-bottom: 1.5rem;
}

.cards__description {
  margin-bottom: 1.5rem;
}

.cards__wrapper {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @include mq(small) {
    grid-template-columns: 1fr 1fr;
  }
}

.card {
  width: 100%;
  padding: 1.5rem;
  background-color: var(--clr-blue-lightest);
  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }

  @include mq(small) {
    padding: 2.5rem 2rem;
    margin-top: 0;
  }
}

.card__link {
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.card__title {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.card__text {
  color: var(--clr-blue);
}

.card__image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.card__image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card__link-icon {
  width: 36px;
  height: 36px;
  object-fit: contain;
  object-position: center;

  & g {
    stroke-width: 4 !important;
  }
}
