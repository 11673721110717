@use '../abstracts/mixins' as *;

.ProgressRoot {
  position: relative;
  overflow: hidden;
  background-color: var(--clr-blue-light);
  border-radius: 99999px;
  width: 100%;
  max-width: 300px;
  height: 25px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
}

.ProgressIndicator {
  background-color: var(--clr-blue);
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}
