@use '../abstracts/mixins' as *;

.container {
  width: min(100%, 67rem);
  margin: 0 auto;
  padding: 0 1rem;

  @include mq(small) {
    width: min(100% - 2rem, 69rem);
  }
}
