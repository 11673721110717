@use '../abstracts/mixins' as *;

.contact__information-box {
  margin-top: 1.5rem;
}

.contact__information-box-container {
  color: var(--clr-blue);
  background-color: var(--clr-blue-lightest);
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.contact__information-box-info {
  display: flex;
}

.contact__information-keyword {
  font-weight: 700;
  margin-right: 0.25rem;
}

.contact__information-link {
  display: flex;
  align-items: center;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: all 0.15s ease-in-out;
  margin-left: 0.2rem;

  &:hover,
  &:focus {
    text-decoration: underline;
    text-decoration-color: var(--clr-blue);
  }
}

.contact__information-svg {
  height: 18px;
  width: auto;
  object-fit: contain;
  object-position: center;
  margin-left: 0.25rem;

  & g {
    stroke: var(--clr-blue) !important;
  }
}
